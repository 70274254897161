main {
  padding: 0;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.restaurant-popup h3 {
  font-size: 1.4em;
  margin-bottom: .4em;
}

.restaurant-popup ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.restaurant-popup li {
  margin-bottom: .4em;
}

.restaurant-popup li:last-child {
  margin-bottom: 0;
}

.search-actions {
  text-align: center;
  background-color: var(--launchtime-purple);
}

.search-actions ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-actions li {
  border-right: solid 1px var(--launchtime-purple);
  border-left: solid 1px var(--launchtime-purple);
}

.search-actions button {
  color: white;
  font-weight: bold;
  background-color: var(--launchtime-cyan);
  border: 0;
  padding: .8em 1em;
}